const prod = {
  url: {
    API_URL: 'https://storebuilderapi.papertrell.com',
    API_PT_URL: 'https://api.papertrell.com',
    WEB_PT_URL: 'https://www.papertrell.com'    
  },
  CANVA_API_KEY:'1sa4vfqm-G_wrZKTXzBRX909'
};
const staging = {
  url: {
    API_URL: 'https://storebuilderapi.papertrell.com',
    API_PT_URL: 'https://shadowmblurb.papertrell.com',
    WEB_PT_URL: 'https://shadow.papertrell.com',
  },
  CANVA_API_KEY:'JjfyWGkjtlpXNmhqA03aBqLI'
};
const dev = {
  url: {
    API_URL: 'http://localhost:8000',
    API_PT_URL: 'http://localhost:83',
    WEB_PT_URL: 'https://localhost:444'
  },
  CANVA_API_KEY:'JjfyWGkjtlpXNmhqA03aBqLI'
};

export const config = process.env.REACT_APP_ENV === 'prod' ? prod : (process.env.REACT_APP_ENV === 'staging' ? staging : staging);