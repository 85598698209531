import { func } from 'prop-types';
import appManager from '../../containers/appManager';

const actions = {
    DRAFT_THEME: 'DRAFT_THEME',
    ADD_SHELFID : 'ADD_SHELFID',
    SELECTED_STORE: 'STORE_PROPS',
    PREVIEW_MODE : 'PREVIEW_MODE',
    SELECTED_PREVIEW: 'SELECTED_PREVIEW',
    UPDATE_EDIT_DRAFT_THEME: 'UPDATE_EDIT_DRAFT_THEME',
    UPDATE_EDIT_BLOCK: 'UPDATE_EDIT_BLOCK',
    PAGE_OPTIONS:'PAGE_OPTIONS',
    draftEdit: (draftTheme) => {
      return {
        type: actions.DRAFT_THEME,
         draftTheme 
      };
    },
   
    updateEditBlock: (editBlock) => {
      return {
        type: actions.UPDATE_EDIT_BLOCK,
        editBlock
      };
    },
    updateEditDraftTheme: (editDraftTheme) => {
      return {
        type: actions.UPDATE_EDIT_DRAFT_THEME,
        editDraftTheme 
      };
    },
    selectedStore: (storeProps) => {
      return {
        type: actions.SELECTED_STORE,
         storeProps 
      };
    },
    addShelfId:(shelfid)=>{
        return{
            type: actions.ADD_SHELFID,
            shelfid 
        }
    },
    changePreviewMode:(previewMode)=>{
        return{
            type: actions.PREVIEW_MODE,
            previewMode 
        }
    },
    changeCurrentPreview:(selectedPreview)=>{
        return{
            type: actions.SELECTED_PREVIEW,
            selectedPreview 
        }
    }
  };

  function updatePageOptions(props) {
    return function (dispatch) {
      if(props) {
        const headers = {
          'Authorization': `Token ` + props.jwtToken
        };
        return appManager.getPages(props.draft_theme.shelfId, headers)
        .then(function(response) {
          let data = response;
        var pagesDropdown = { public: [], private: [] };
            data['private'].map((page, index) => {
                const obj = {
                    key: page.key,
                    value: page.value,
                    state: 'private'
                };
                pagesDropdown.private.push(obj);
            })
            data['public'].map((page, index) => {
                const obj = {
                    key: page.key,
                    value: page.value,
                    state: 'public'
                };
                pagesDropdown.public.push(obj);
            })

        dispatch({
          type: actions.PAGE_OPTIONS,
          pageOptions :pagesDropdown
        })
        });
      }
    }
  }
  export default actions;
  export {updatePageOptions};