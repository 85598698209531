import actions from './actions';

const initState = { idToken: null, isFirstTime: true , jwtToken : null };

export default function authReducer(state = initState, action) {

  switch (action.type) {

    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
      };
    case actions.LOGOUT:
      return initState;
    case actions.SAVE_ACCESS_TOKEN:
      //localStorage.setItem("id_token", action.token)
      return {
        ...state,
        idToken: action.token
      }
      case actions.SAVE_JWT_TOKEN:
        //localStorage.setItem("id_token", action.token)
        return {
          ...state,
          jwtToken: action.jwtToken
        }
    case actions.SET_FIRSTTIME:
      return {
        ...state,
        isFirstTime: action.isFirstTime
      }
    default:
      return state;
  }
}
