import axios from 'axios';
import { apis } from '../endPoints'
import XMLParser from 'react-xml-parser';

const appManager = {
    previewOptions : ['desktop', 'mobile'],
    capitalize: (firstName, lastName) => {
        return firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
    },
    getEditedTheme: (shelfId, headers) => {
        var data = { shelfId: shelfId };
        return new Promise((resolve, reject) => {
            axios.post(apis.THEME_DRAFT_API, data, {
                headers: headers,
            }).then(function (response) {
                    var data = response.data.data;
                    if(data)
                        resolve({...data.data, lastModified : data.lastModified,shelfId});
                    else
                        resolve(data);
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    getStores: (token,props) => {
        return new Promise((resolve, reject) => {
            console.log(apis.MY_STORES_API+token);
            axios.get(apis.MY_STORES_API+token)
                .then(function (response) {
                    const error = parseXmlError(response);
                    if(error) {
                        reject(error);
                    }
                    
                    var xml = new XMLParser().parseFromString(response.data);
                    console.log("xml", xml)
                    
                    const stores = xml.children.find(e => e.name === "Shelves");
                    if (stores && stores.children)
                        resolve({ stores: stores.children, props: props });
                    else
                        resolve({ stores : [],props : props});
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    getStore: (shelfId) => {
        return new Promise((resolve, reject) => {
            console.log(apis.STORE_PROPERTIES_API);
            axios.get(apis.STORE_PROPERTIES_API+ shelfId)
                .then(function (response) {
                    if (response.data != null)
                        resolve({ store : response.data  });
                    else {
                        reject({store : null })
                    }
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    validateToken: (token,headers, props) => {
        var data = { accessToken: token };
        return new Promise((resolve, reject) => {
            axios.post(apis.VALIDATE_TOKEN_API , data,{
                headers: headers
            }).then(function (response) {
                    resolve({ ...response.data });
                })
                .catch(function (error) {
                    console.log("ERRRR:: ",error);
                    resolve({ error });
                });
        })
    },
    saveEditedTheme: (shelfid,draftData, headers) => {
        var data = { shelfId: shelfid , data : draftData };
        return new Promise((resolve, reject) => {
            axios.post(apis.SAVE_THEME_DRAFT_API, data, {
                headers: headers,
            }).then(function (response) {
                    var data = response.data;
                    resolve(data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject({ error });
                });
        })
    },
    publishEditedTheme: (shelfid,draftData, headers) => {
        var data = { shelfId: shelfid , data : draftData };
        return new Promise((resolve, reject) => {
            axios.post(apis.PUBLISH_THEME_API, data, {
                headers: headers,
            }).then(function (response) {
                    var data = response.data;
                    resolve(data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject({ error });
                });
        })
    },
    clearCache: (shelfId ) => {
        return new Promise((resolve, reject) => {
            axios.get(apis.CLEAR_API_CACHE+shelfId ).then(function (response) {
                 var json = new XMLParser().parseFromString(response.data); 
                    resolve({ status : json.children[1].value});
                })
                .catch(function (error) {
                    console.log("ERRRR:: ",error);
                    reject({ error });
                });
        })
    },
    uploadDesignBanner: (shelfId, imgUrl , designId , headers) => {
        var data = { shelfId  , imgUrl ,designId };
        return new Promise((resolve, reject) => {
            axios.post(apis.UPLOAD_BANNER_URL_API, data, {
                headers: headers,
            }).then(function (response) {
                    var data = response.data;
                    resolve(data);
                })
                .catch(function (error) {
                    console.log(error);
                    reject({ error });
                });
        })
    },
    getTitles: (shelfId,searchText,pagenum,pagesize) => {
        return new Promise((resolve, reject) => {
            console.log(apis.GET_TITLES_API);
            //axios.post(`https://localhost:444/web/Handlers/BookShelf/BookShelfHandler.ashx?mode=getShelfCatalogAppsForCollectionsAndBanners&ShelfId=${shelfId}&pagenum=${pagenum}&pagesize=${pagesize}&searchText=${searchText}`, null ,{
            axios.post(apis.GET_TITLES_API+`ShelfId=${shelfId}&pagenum=${pagenum}&pagesize=${pagesize}&searchText=${searchText}`, null ,{  
            headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(function (response) {
                    if (response.data != null)
                        resolve({ titles : response.data.Apps});
                    else {
                        reject({store : null })
                    }
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    getTags: (shelfId,searchText,pagenum,pagesize) => {
        return new Promise((resolve, reject) => {
            axios.post(apis.GET_TAGS_API+`ShelfId=${shelfId}&pagenum=${pagenum}&pagesize=${pagesize}&searchText=${searchText}`, null ,{  
            headers : {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
                }})
                .then(function (response) {
                    if (response.data != null)
                        resolve({ tags : response.data.Tags});
                    else {
                        reject({store : null })
                    }
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    getPages: (shelfId , headers) => {
        var data = { shelfId: shelfId };
        return new Promise((resolve, reject) => {
            axios.post(apis.GET_PAGES_API, data).then(function (response) {
                    var data = response.data.data;
                    if(data)
                        resolve({...data });
                    else
                        resolve(data);
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },   
    getPageContent: (shelfId ,page, headers) => {
        var data = { shelfId , page   };
        return new Promise((resolve, reject) => {
            axios.post(apis.GET_PAGES_CONTENT_API, data).then(function (response) {
                    var data = response.data.data;
                    if(data)
                        resolve({...data });
                    else
                        resolve(data);
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    savePage: (shelfId ,page, content, headers) => {
        var data = { shelfId , page ,content  };
        console.log("saveapage",data);
        return new Promise((resolve, reject) => {
            axios.post(apis.SAVE_PAGES_API, data).then(function (response) {
                    var data = response.data;
                    if(data)
                        resolve({...data });
                    else
                        resolve(data);
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    deletePage: (shelfId ,page, headers) => {
        var data = { shelfId , page   };
        console.log("deletePage",data);
        return new Promise((resolve, reject) => {
            axios.post(apis.DELETE_PAGES_API, data).then(function (response) {
                    var data = response.data;
                    if(data)
                        resolve({...data });
                    else
                        resolve(data);
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
    convertObjToPoints: (obj) => {
        let newObj = {
            ...obj,
            paddingLeft: obj && obj.paddingLeft ? appManager.convertToPoints(obj.paddingLeft) : 0,
            paddingRight: obj && obj.paddingRight ? appManager.convertToPoints(obj.paddingRight) : 0,
            paddingBottom:obj && obj.paddingBottom ? appManager.convertToPoints(obj.paddingBottom) : 0,
            paddingTop: obj && obj.paddingTop ? appManager.convertToPoints(obj.paddingTop) : 0,
            fontSize: obj && obj.fontSize ? appManager.convertToPoints(obj.fontSize) : '16pt',
        }
        return newObj;
    },
    convertToPoints: (val) => {
        if(val && !val.toString().includes('pt')){
            val = val + 'pt';
            return val;
        }
        return val;
    },
    getStoreProperties: (shelfid) => {
        const url = `${apis.GET_STORE_PROPERTIES}${shelfid}`
        return new Promise((resolve, reject) => {
            console.log(url);
            axios.get(url)
            .then(function (response) {
                if (typeof response.data === "string" && response.data !== "") {
                    var xml = new XMLParser().parseFromString(response.data);
                    console.log("xml", xml)
                    if (xml.name === "Error")
                        reject(xml);
                }
                resolve(response.data)

            })
            .catch(function (error) {
                reject({ error });
            });
        })
    },
    getMediaFiles: (shelfId,headers) => {
        var data = { shelfId: shelfId };
        return new Promise((resolve, reject) => {
            axios.post(apis.GET_STORE_MEDIA_API, data, {
                headers: headers,
            }).then(function (response) {
                    var data = response.data.data;
                    if(data)
                        resolve({...data,shelfId});
                    else
                        resolve(data);
                })
                .catch(function (error) {
                    reject({ error });
                });
        })
    },
}

function parseXmlError(response) {
    if (typeof response.data === "string") {
        var xml = new XMLParser().parseFromString(response.data);
        if (xml && xml.name === "Error" && xml.children && xml.children.length > 0) {
            const statusCode = xml.children.find(e => e.name === "StatusCode");
            if (statusCode.value === "401") {
                return ({ statusCode: 401, status: "Unauthorized" });
            }
            const status = xml.children.find(e => e.name === "Status");
            if (status.value === "Invalid")
                return ({ statusCode: 0, status: "Invalid" });
            return (xml);
        }
    }
    return null;
}

function checkUnauthorized(error) {
    if(error && error.statusCode && error.statusCode === 401) {
        return true;
    }
    return false;
}
export {checkUnauthorized};

export default appManager;