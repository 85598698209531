const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SAVE_ACCESS_TOKEN: 'SAVE_ACCESS_TOKEN',
  SAVE_JWT_TOKEN: 'SAVE_JWT_TOKEN',
  SET_ISFIRSTTIME: 'SET_ISFIRSTTIME',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }), 
  saveToken:(token)=>{
    // console.log("inside redux save token", token)
    //localStorage.setItem("id_token", token);
    return{
        type: actions.SAVE_ACCESS_TOKEN,
        token
    }
  },
  saveJwtToken:(jwtToken)=>{
    // console.log("inside redux save token", token)
    //localStorage.setItem("id_token", token);
    return{
        type: actions.SAVE_JWT_TOKEN,
        jwtToken
    }
  },
  setIsFirstTime:(value)=>{
    return{
        type: actions.SET_ISFIRSTTIME,
        value
    }
  }
};
export default actions;
