import actions from './actions';

const initState = {
  draftTheme: null,
  storeProps: null,
  previewMode: false,
  selectedPreview: 'desktop',
  editBlock: null,
  editDraftTheme: null,
  pageOptions: null
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.DRAFT_THEME:
      return {
        ...state,
        draftTheme: action.draftTheme
      }
    case actions.UPDATE_EDIT_DRAFT_THEME: 
      return {
        ...state,
        editDraftTheme: action.editDraftTheme
      }
    case actions.UPDATE_EDIT_BLOCK:
      return {
        ...state,
        editBlock: action.editBlock
      }
    case actions.SELECTED_STORE:
      return {
        ...state,
        storeProps: action.storeProps
      }
    case actions.ADD_SHELFID:
      return {
        ...state,
        shelfId: action.shelfId
      }
    case actions.PREVIEW_MODE:
        return {
          ...state,
          previewMode: action.previewMode
        }
      case actions.SELECTED_PREVIEW:
        return {
          ...state,
          selectedPreview: action.selectedPreview
        } 
      case actions.PAGE_OPTIONS:
        return {
          ...state,
          pageOptions: action.pageOptions
        }
    default:
      return state;
  }
}
