import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import history from '@iso/lib/helpers/history';
import authActions from '@iso/redux/auth/actions'
import appManager from './containers/appManager'
// import Dashboard from './containers/Dashboard/Dashboard';


const Dashboard = lazy(() => import('@iso/containers/Dashboard/Dashboard'));
// const templateID = lazy(() => import('@iso/containers/preview/SelectTemplate'));
const publicRoutes = [
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/public/Signin'))
  } 
]
const privateRoutes = [
  {
    path: PUBLIC_ROUTE.PREVIEW_SHELF,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
    exact: true,
  }, 
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  }
];
const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn != null ?
        (
          <Component {...props} />
        )
        : (
          <Redirect
            to={{
              pathname: PUBLIC_ROUTE.SIGN_IN,
              state: { from: props.location }
            }}
          />
        )
    }
  />
);
 

export default function Routes() {
  const [isLoading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const { saveToken , saveJwtToken } = authActions;
   let loggedToken =   useSelector(state => state.Auth.idToken);

   const query = new URLSearchParams(window.location.search);
   const urlToken = query.get('accesstoken') ;

  React.useEffect(() => {
    setLoading(true);
    if (loggedToken === null || (urlToken && loggedToken !== urlToken)) {
        appManager.validateToken(urlToken ? urlToken : loggedToken)
          .then(data => {
            if (data) {
              if (data.status === 200) {
                dispatch(saveToken(urlToken));
                dispatch(saveJwtToken(data.token));
              }else{
                //go back to login page
                dispatch(saveToken(null));
                dispatch(saveJwtToken(null));
              }
            }
          });
    }
    else{
      setLoading(false);
    }
  }, [loggedToken, urlToken, dispatch, saveToken, saveJwtToken]);
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <Switch>
          {publicRoutes.map((route, index) => {
             const { path, component, exact, ...otherProps } = route;
             return(
              <Route key={index} path={path} exact={exact}>
                <route.component history ={history} {...otherProps} />
              </Route>
             )
          }
          )}
             {privateRoutes.map(singleRoute => {
              const { path, component, exact, ...otherProps } = singleRoute;
              return (
                <RestrictedRoute
                  key={path}
                  path={path}
                  component={Dashboard}
                  isLoggedIn={loggedToken}
                  {...otherProps}
                />
              );
            })} 
            {/* <PrivateRoute
              path="/"
              component={Dashboard}
              isLoggedIn={loggedToken}
            /> */}
            
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
