import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
//import { store } from './redux/store';
import configureStore from "./redux/store";
import history from '@iso/lib/helpers/history';
import { PersistGate } from "redux-persist/lib/integration/react";

const { store, persistor } = configureStore();
const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes history ={history}/>
      </>
    </AppProvider>
    </PersistGate>
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
