import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Shelf from '@iso/redux/shelf/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const middlewares = [thunk];

function configureStore(initialState = {}) {
  const reducer = combineReducers({
    Auth:persistReducer(
      {
        key: "Auth",  
        storage,
        debug: true,
        blacklist: [],
      },
      Auth
    ),
    App,
    Shelf:persistReducer(
      {
        key: "Shelf", 
        storage,
        debug: true,
        blacklist: ['editBlock'],
      },
      Shelf
    ),
    ThemeSwitcher:persistReducer(
      {
        key: "ThemeSwitcher",  
        storage,
        debug: true,
        blacklist: [],
      },
      ThemeSwitcher
    ),
    LanguageSwitcher:persistReducer(
      {
        key: "LanguageSwitcher",  
        storage,
        debug: true,
        blacklist: [],
      },
      LanguageSwitcher
    ),
    auth: () => ({ mock: true }),
  });

  const store = createStore(persistReducer({
    key: "root",
    debug: true,
    storage,
    blacklist: ['App'],
  }, reducer), composeEnhancers(applyMiddleware(...middlewares)));


  const persistor = persistStore(store, null, () => {
    // if you want to get restoredState
  });

  return { store, persistor };
}

export default configureStore;
