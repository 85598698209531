import { config } from './constants'

export const apis = {
    ACCESS_TOKEN: '7084fb0f50e343db8cf36fc574a86b30',

    //http://api.papertrell.com/Store/home?accesstoken=f80bc43fc70f45d884f4af7184ea94a0
    THEME_DRAFT_API: `${config.url.API_URL}/store/theme/draft`,
    SAVE_THEME_DRAFT_API: `${config.url.API_URL}/store/theme/draft/save`,
    PUBLISH_THEME_API: `${config.url.API_URL}/store/theme/publish`,
    UPLOAD_BANNER_URL_API: `${config.url.API_URL}/store/upload/bannerurl`,
    UPLOAD_BANNER_API: `${config.url.API_URL}/store/upload/banner`,
    UPLOAD_PAGE_MEDIA_API :`${config.url.API_URL}/store/upload/page/media`,
    GET_PAGES_API:`${config.url.API_URL}/store/pages/all`,
    SAVE_PAGES_API:`${config.url.API_URL}/store/pages/save`,
    GET_PAGES_CONTENT_API:`${config.url.API_URL}/store/pages/content`,
    DELETE_PAGES_API:`${config.url.API_URL}/store/pages/delete`,
    GET_STORE_MEDIA_API: `${config.url.API_URL}/store/media/files`,
    //UPLOAD_BANNER_API: `http://localhost:8000/store/upload/banner`,
    //UPLOAD_PAGE_IMAGE_API :`http://localhost:8000/store/upload/page/image`,
    //GET_PAGES_API:`http://localhost:8000/store/pages/all`,
    // SAVE_PAGES_API:`http://localhost:8000/store/pages/save`,
    // GET_PAGES_CONTENT_API:`http://localhost:8000/store/pages/content`,
    // DELETE_PAGES_API:`http://localhost:8000/store/pages/delete`,


    VALIDATE_TOKEN_API: `${config.url.API_URL}/token/validate`,
    //http://api.papertrell.com/UserShelfs/GetAllShelfsByUserId?AccessToken=067614990ebc42d3b8628e601bdc13e5&shelfid=b326490f-0724-4c49-b090-6f35b04b0afe&langCode=en
    MY_STORES_API: `${config.url.API_PT_URL}/v2/User/Stores?accessToken=`,
    STORE_PROPERTIES_API: `${config.url.API_PT_URL}/v2/store/properties?appid=`,
    CLEAR_API_CACHE:`${config.url.API_PT_URL}/PapertrellCache/RemoveCachedKey?shelfid=`,

    LOGIN_URL:`${config.url.WEB_PT_URL}/web/ssl/login.aspx`,
    GET_TITLES_API:`${config.url.WEB_PT_URL}/web/Handlers/BookShelf/BookShelfHandler.ashx?mode=getShelfCatalogAppsForCollectionsAndBanners&`,
    GET_TAGS_API:`${config.url.WEB_PT_URL}/web/Handlers/BookShelf/BookShelfHandler.ashx?mode=getAvailableTagsForShelf&`,

    GET_STORE_PROPERTIES: `${config.url.API_PT_URL}/v2/store/properties?shelfid=`
};

